
.goods-info-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .link-box {
        .view-detail {
            ::v-deep .el-link--inner {
                color: #1AB163;
            }
        }
    }
    .goods-img-dialog {
        .article-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 550px;
            .content {
               text-align: center;
                height: 100%;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        height: 100%;
                    }
                }
            }
            .top-title {
                text-align: center;
                font-size: 20px;
                color: #333333;
            }

            .top-author {
                margin-top: 12px;
                text-align: center;
                font-size: 14px;
                color: #666666;
            }

            .top-content {
                margin-top: 20px;

                ::v-deep p {
                    img {
                        max-width: 900px;
                    }
                }
            }
            .top-img{
                margin-top: 40px;
                position: relative;
                // left: 25%;
            }
        }
        .upload-file-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 445px;
        }
    }
}
.theory-list-header{
    display: flex;
    align-items: center;
    span{
        line-height: 1;
    }
}
.submit-score {
    margin-left: 10px;
    color: #FF9450;
    font-size: 16px;
    cursor: pointer;
}
